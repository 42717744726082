import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import SecondaryHero from "../../components/secondary-hero";

const PreviousProjectsPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wpPageContent(id: { eq: "cG9zdDo5MTA=" }) {
				content
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Fundraising",
				item: {
					url: `${siteUrl}/fundraising`,
					id: `${siteUrl}/fundraising`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Previous Projects",
				item: {
					url: `${siteUrl}/fundraising/previous-projects`,
					id: `${siteUrl}/fundraising/previous-projects`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Previous Projects | Friends of Eastbourne Hospital"
				description="Special projects since the year 2000."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/fundraising/previous-projects`,
					title: "Previous Projects | Friends of Eastbourne Hospital",
					description: "Special projects since the year 2000.",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="pb-5">
					<SecondaryHero
						title="Previous projects"
						description={<span>Special projects since the year 2000</span>}
					/>
				</section>
				<section className="pb-md-7 pb-5">
					<Container>
						<Row>
							<Col className="page-content previous-projects">
								<div className="clearfix">
									{parse(data.wpPageContent.content)}
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default PreviousProjectsPage;
